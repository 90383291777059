
import { Options, Vue } from 'vue-class-component';
import { InjectReactive, Prop } from 'vue-property-decorator';
import RadioButtonGroup from '@/common/components/form-ui/radio-button-group.component.vue';
import CreditCardPaymentMethod from '@/modules/order/details/components/payment/credit-card-payment.component.vue';
import { orderModule, RefundTypeAPI, ModelError } from '@/modules/order/orderModule.vuex-module';
import { OrderPayment } from '@/common/models/OrderPayment.model';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import { EntityHierarchy } from '@/common/models/EntityHierarchy.model';
import { Time } from '@/common/models/Time.model';
import { clientModule } from '@/modules/client/client.vuex-module';
import { PaymentMethod } from '@/common/enums/PaymentMethod.enum';

type RadioButtonOption = {
	label: string;
	value: string;
};

@Options({
	name: 'TimeRefundView',
	components: { CreditCardPaymentMethod, RadioButtonGroup },
})
export default class TimeWarnView extends Vue {
	@Prop({ default: 'false' }) cancellation!: string;
	@InjectReactive() time?: Time;
	refundTypeAPI: RefundTypeAPI = 'RefundBalanceToGiftCard';
	error: string | null = null;
	loading = false;
	get activity(): EntityHierarchy | null {
		return orderModule.openActivity?.activity ?? null;
	}
	formatPrice(price: number): string {
		let USDollar = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		return USDollar.format(price);
	}
	get payments(): OrderPayment[] {
		let bal = Math.abs(orderModule._activeOrder?.balance ?? 0);
		return orderModule._activeOrder?.paidPayments.filter((p) => p.RefundMaxAmount >= bal) ?? [];
	}
	isCard(type: number): boolean {
		return type == PaymentMethod.CARD || type == PaymentMethod.ECOMMERCE;
	}
	get uniquePayments(): OrderPayment[] {
		return this.payments.filter((item, idx) => this.isCard(item.PaymentMethodTypeKey) && this.payments.findIndex((p) => item.CardBrand + item.LastFourDigits == p.CardBrand + p.LastFourDigits) === idx);
	}
	get refundOptions(): RadioButtonOption[] {
		var options: RadioButtonOption[] = [];
		if (this.activity?.AllowRefundToGiftCard) {
			options.push({
				label: 'Refund to gift card',
				value: 'RefundBalanceToGiftCard',
			});
		}
		if (this.activity?.AllowRefundToCard) {
			options = options.concat(
				this.uniquePayments.map((payment) => {
					return {
						label: `Refund to <b>${payment.CardBrand ?? 'Card'} *${payment.LastFourDigits}</b>`,
						value: 'RefundToCardOnFile|' + payment.OrderPaymentKey,
					};
				}),
			);
		}
		return options;
	}
	get balance(): number {
		return orderModule._activeOrder?.balance ?? 0;
	}
	makeitsonumberone(): void {
		this.loading = true;
		console.log(this.$route.params);
		orderModule.issueRefund(this.refundTypeAPI.split('|')[0] as RefundTypeAPI, !!this.$route.params.cancellation, this.$route.params.orderEntityTimeKey as string, this.refundTypeAPI.split('|')[1]).then((res) => {
			this.loading = false;
			if (!res) {
				this.error = `Unable to process refund. Please call us at ${clientModule.location?.PublicPhoneNumber}`;
			} else if (res.Order) {
				if (res.Order.Balance) {
					this.error = `Unable to process refund. Please call us at ${clientModule.location?.PublicPhoneNumber}`;
					console.error(`Error processing Refund: Balance is still ${res.Order.Balance}.`);
				} else {
					if (this.cancellation == 'true') {
						this.$router.push(`/manage/${this.$route.params.clientLocationKey}/${this.$route.params.orderKey}?cancellation=true`);
					} else {
						this.$router.push({ name: 'OrderTime', query: { success: 'true' } });
					}
				}
			} else if (res.ModelErrors) {
				this.error = res.ModelErrors.map((e: any) => e.Value)
					.flat()
					.join(', ');
			} else {
				this.error = `Unable to process refund. Please call us at ${clientModule.location?.PublicPhoneNumber}`;
			}
		});
	}
}
